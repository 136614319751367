package com.ecosave.watch.portal.pages

import com.ecosave.watch.portal.components.common.ApiFailedMessage
import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.controlcenter.EnergyKPIComponent
import com.ecosave.watch.portal.components.controlcenter.GeneratedActionsComponent
import com.ecosave.watch.portal.components.controlcenter.PieChartComponent
import com.ecosave.watch.portal.components.controlcenter.TooltipContent
import com.ecosave.watch.portal.components.energymanagement.FacilitySelectionComponent
import com.ecosave.watch.portal.components.npm.Bar
import com.ecosave.watch.portal.components.npm.CartesianGrid
import com.ecosave.watch.portal.components.npm.ComposedChart
import com.ecosave.watch.portal.components.npm.Legend
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.XAxis
import com.ecosave.watch.portal.components.npm.YAxis
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.common.AutoCompleteOption
import com.ecosave.watch.portal.helpers.common.PageTitles
import com.ecosave.watch.portal.helpers.common.formatTick
import com.ecosave.watch.portal.helpers.common.lg
import com.ecosave.watch.portal.helpers.common.md
import com.ecosave.watch.portal.helpers.common.sm
import com.ecosave.watch.portal.helpers.common.xs
import com.ecosave.watch.portal.helpers.controlcenter.labelFormatter
import com.ecosave.watch.portal.models.controlcenter.ActionDataSet
import com.ecosave.watch.portal.models.controlcenter.BarChartVisibility
import com.ecosave.watch.portal.models.controlcenter.EnergyKPIMetric
import com.ecosave.watch.portal.models.controlcenter.PieData
import com.ecosave.watch.portal.models.controlcenter.RadioLabels
import com.ecosave.watch.portal.models.controlcenter.View
import com.ecosave.watch.portal.services.ApiResponse
import com.ecosave.watch.portal.services.billing.getFacilities
import com.ecosave.watch.portal.services.controlcenter.fetchActionData
import com.ecosave.watch.portal.services.controlcenter.fetchComposedData
import com.ecosave.watch.portal.services.controlcenter.fetchEnergyData
import com.ecosave.watch.portal.services.controlcenter.fetchPieData
import com.ecosave.watch.portal.useGlobalState
import js.core.jso
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.FormControlLabel
import mui.material.Grid
import mui.material.Paper
import mui.material.Radio
import mui.material.RadioGroup
import mui.material.Typography
import mui.material.TypographyAlign
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.Color
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px
import com.ecosave.watch.portal.components.npm.Tooltip as TTip
import com.ecosave.watch.portal.styles.ControlCenterPageStyles as styles

val ControlCenter = FC<Props> {
    var activeRadio by useState(RadioLabels.BOTH)
    var views by useState(arrayOf(View.USAGE, View.COST))

    var energyStarTileValue by useState(ApiResponse(fetched = false, isError = false, data = EnergyKPIMetric()))
    var actionTileValue by useState(ApiResponse(fetched = false, isError = false, data = ActionDataSet()))
    var composedDataStream: ApiResponse<String> by useState(ApiResponse(fetched = false, isError = false, data = ""))
    var pieDataStream: ApiResponse<List<PieData>> by useState(ApiResponse(fetched = false, isError = false, data = emptyList()))
    val globalState = useGlobalState()
    var barChartDataArray by useState("")
    var barChartVisibility by useState(BarChartVisibility())
    var activeOption by useState<AutoCompleteOption>()
    var facilityOptions by useState<Array<AutoCompleteOption>>(emptyArray())

    useEffect(composedDataStream) {
        if (composedDataStream.data != "") {
            val data: dynamic = composedDataStream.data
            barChartDataArray = data.utilityBillKPI
            barChartVisibility = BarChartVisibility(
                electric = data.utilities.electric,
                naturalGas = data.utilities.naturalGas,
                steam = data.utilities.steam
            )
        }
    }

    fun fetchData(facilityId: Number) {
        energyStarTileValue = ApiResponse(fetched = false, isError = false, data = EnergyKPIMetric())
        actionTileValue = ApiResponse(fetched = false, isError = false, data = ActionDataSet())
        composedDataStream = ApiResponse(fetched = false, isError = false, data = "")
        pieDataStream = ApiResponse(fetched = false, isError = false, data = emptyList())
        mainScope.launch {
            energyStarTileValue = fetchEnergyData(facilityId)
            actionTileValue = fetchActionData(facilityId)
            composedDataStream = fetchComposedData(facilityId)
            pieDataStream = fetchPieData(facilityId)
        }
    }

    useEffectOnce {
        globalState.updatePageTitle(PageTitles.CONTROL_CENTER.title)
        mainScope.launch {
            val facilities = getFacilities()
            if (facilities.isNotEmpty()) {
                fetchData(facilities[0].facilityId)
                facilityOptions = facilities.map { item -> AutoCompleteOption(item.facilityName, "${item.facilityId}") }.toTypedArray()
                activeOption = AutoCompleteOption(facilities[0].facilityName, "${facilities[0].facilityId}")
            } else {
                energyStarTileValue = ApiResponse(fetched = true, isError = true, data = EnergyKPIMetric())
                actionTileValue = ApiResponse(fetched = true, isError = true, data = ActionDataSet())
                composedDataStream = ApiResponse(fetched = true, isError = true, data = "")
                pieDataStream = ApiResponse(fetched = true, isError = true, data = emptyList())
            }
        }
    }

    val isBoth = activeRadio == RadioLabels.BOTH

    Grid {
        container = true
        spacing = responsive(3)
        Grid {
            item = true
            xs = 12
            Typography {
                className = styles.TEXT_CENTER.cssClass
                variant = TypographyVariant.subtitle1
                +activeRadio.title
            }
        }
        Grid {
            container = true
            item = true
            xs = 12
            Grid {
                item = true
                xs = 6
                Box {
                    sx {
                        display = Display.flex
                        marginLeft = 10.px
                    }
                    Typography {
                        variant = TypographyVariant.h6
                        component = ReactHTML.h6
                        align = TypographyAlign.center
                        sx {
                            marginRight = 20.px
                        }
                        +"Facility: "
                    }
                    Box {
                        FacilitySelectionComponent {
                            name = "facility"
                            options = facilityOptions
                            value = activeOption ?: AutoCompleteOption("", "")
                            onSelect = {
                                activeOption = it
                                fetchData(it.value.toInt())
                            }
                        }
                    }
                }
            }
            Grid {
                item = true
                xs = 6
                RadioGroup {
                    sx {
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.flexEnd
                    }
                    value = activeRadio.value
                    onChange = { _, value ->
                        when (value) {
                            RadioLabels.USAGE.value -> {
                                activeRadio = RadioLabels.USAGE
                                views = arrayOf(View.USAGE)
                            }

                            RadioLabels.COST.value -> {
                                activeRadio = RadioLabels.COST
                                views = arrayOf(View.COST)
                            }

                            else -> {
                                activeRadio = RadioLabels.BOTH
                                views = arrayOf(View.USAGE, View.COST)
                            }
                        }
                    }
                    for (radioLabel in RadioLabels.values()) {
                        FormControlLabel {
                            value = radioLabel.value
                            control = Radio.create()
                            label = ReactNode(radioLabel.label)
                        }
                    }
                }
            }
        }

        for (activeView in views) {
            Grid {
                item = true
                sm = 12
                md = if (isBoth) 6 else 12
                Box {
                    className = styles.STACK_CHART_CONTAINER.cssClass
                    Paper {
                        className = if (isBoth) {
                            styles.CHART_SECTOR_TWO.cssClass
                        } else {
                            styles.CHART_SECTOR_ONE.cssClass
                        }
                        if (composedDataStream.isError) {
                            ApiFailedMessage {}
                        } else {
                            ApiLazyRender {
                                dataFetched = composedDataStream.fetched
                                if (!barChartVisibility.electric && !barChartVisibility.naturalGas && !barChartVisibility.steam) {
                                    Typography {
                                        className = styles.TEXT_CENTER.cssClass
                                        variant = TypographyVariant.subtitle1
                                        sx {
                                            color = Color(Colors.ORANGE_RED)
                                        }
                                        +"No data found"
                                    }
                                } else {
                                    ResponsiveContainer {
                                        width = "100%"
                                        height = "100%"
                                        ComposedChart {
                                            width = 500
                                            height = 300
                                            data = barChartDataArray
                                            CartesianGrid {
                                                stroke = Colors.WHITE_SMOKE
                                                vertical = false
                                            }
                                            XAxis {
                                                dataKey = "billingPeriod"
                                                tick = jso {
                                                    fill = Colors.WHITE
                                                    fontSize = 12
                                                }
                                            }
                                            YAxis {
                                                type = "number"
                                                width = 110
                                                tickCount = 9
                                                tick = jso {
                                                    fill = Colors.WHITE
                                                    fontSize = 12
                                                }
                                                label = jso {
                                                    value = activeView.yLabel
                                                    fill = Colors.WHITE
                                                    position = "insideLeft"
                                                    textAnchor = "middle"
                                                    angle = -90
                                                }
                                                tickFormatter = ::formatTick
                                            }
                                            TTip {
                                                content = TooltipContent
                                                labelStyle = jso {
                                                    color = Colors.BLACK
                                                }
                                            }
                                            Legend {
                                                iconSize = 20
                                                iconType = "wye"
                                                formatter = ::labelFormatter
                                            }
                                            if (barChartVisibility.steam) {
                                                Bar {
                                                    barSize = 25
                                                    stackId = "1"
                                                    "steam${activeView.value}".also { dataKey = it }
                                                    fill = Colors.GOLDENROD
                                                }
                                            }
                                            if (barChartVisibility.naturalGas) {
                                                Bar {
                                                    stackId = "1"
                                                    barSize = 25
                                                    "naturalGas${activeView.value}".also { dataKey = it }
                                                    fill = Colors.BRIGHT_GREEN
                                                }
                                            }
                                            if (barChartVisibility.electric) {
                                                Bar {
                                                    barSize = 25
                                                    stackId = "1"
                                                    "electric${activeView.value}".also { dataKey = it }
                                                    fill = Colors.LIGHT_CYAN_BLUE
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }

        if (views.contains(View.USAGE)) {
            Grid {
                item = true
                xs = 12
                sm = 6
                md = 4
                lg = if (isBoth) 3 else 4
                Paper {
                    className = styles.PERCENT_TILE.cssClass
                    PieChartComponent {
                        pieTitle = View.USAGE.title
                        dataFetched = pieDataStream.fetched
                        data = pieDataStream.data
                        dataKey = View.USAGE.dataKey
                        isError = pieDataStream.isError
                    }
                }
            }
        }
        Grid {
            item = true
            xs = 12
            sm = 6
            md = 4
            lg = if (isBoth) 3 else 4
            Paper {
                className = styles.PERCENT_TILE.cssClass
                EnergyKPIComponent {
                    isError = energyStarTileValue.isError
                    dataFetched = energyStarTileValue.fetched
                    energyKPIData = energyStarTileValue.data
                }
            }
        }

        if (views.contains(View.COST)) {
            Grid {
                item = true
                xs = 12
                sm = 6
                md = 4
                lg = if (isBoth) 3 else 4
                Paper {
                    className = styles.PERCENT_TILE.cssClass
                    PieChartComponent {
                        isError = pieDataStream.isError
                        pieTitle = View.COST.title
                        dataFetched = pieDataStream.fetched
                        data = pieDataStream.data
                        dataKey = View.COST.dataKey
                    }
                }
            }
        }
        Grid {
            item = true
            xs = 12
            sm = 6
            md = 4
            lg = if (isBoth) 3 else 4
            Paper {
                className = styles.PERCENT_TILE.cssClass
                GeneratedActionsComponent {
                    this.isError = actionTileValue.isError
                    this.actionDataFetched = actionTileValue.fetched
                    this.actionTileValue = actionTileValue.data
                    this.facilityId = activeOption?.value ?: ""
                    this.facilityName = activeOption?.label ?: ""
                }
            }
        }
    }
}