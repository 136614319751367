package com.ecosave.watch.portal.components.energymanagement

import com.ecosave.watch.portal.components.common.ApiLazyRender
import com.ecosave.watch.portal.components.npm.Bar
import com.ecosave.watch.portal.components.npm.BarChart
import com.ecosave.watch.portal.components.npm.CartesianGrid
import com.ecosave.watch.portal.components.npm.ResponsiveContainer
import com.ecosave.watch.portal.components.npm.XAxis
import com.ecosave.watch.portal.components.npm.YAxis
import com.ecosave.watch.portal.helpers.Colors
import com.ecosave.watch.portal.helpers.energymanagement.RadioLabels
import com.ecosave.watch.portal.models.energymanagement.RankingData
import com.ecosave.watch.portal.models.energymanagement.RankingKPIMetric
import js.core.jso
import js.core.push
import mui.material.Box
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Modal
import mui.material.Paper
import mui.material.Size
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.useState
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Overflow
import web.cssom.TextAlign
import web.cssom.pct
import web.cssom.px
import com.ecosave.watch.portal.components.npm.Tooltip as TTip
import com.ecosave.watch.portal.styles.EnergyManagementStyles as styles

external interface RankingComponentProps : Props {
    var data: List<RankingKPIMetric>
    var dataFetched: Boolean
    var activeRadio: RadioLabels
    var title: String
}

val RankingComponent = FC<RankingComponentProps> { props ->
    var isOpen by useState(false)
    var convertedData = arrayOf<RankingData>()
    for (metric in props.data) {
        convertedData.push(
            jso {
                facilityName = metric.facilityDetails.facilityName
                facilityId = metric.facilityDetails.facilityId
                facilityDescription = metric.facilityDetails.facilityDescription
                co2Intensity = metric.energyKPIMetric.co2Intensity
                siteEnergyUseIntensity = metric.energyKPIMetric.siteEnergyUseIntensity
                costIntensity = metric.energyKPIMetric.costIntensity
            }
        )
    }

    var activeValueLabel = "score"
    if (props.activeRadio == RadioLabels.COST) {
        convertedData.sortBy { it.costIntensity }
        activeValueLabel = "costIntensity"
    } else if (props.activeRadio == RadioLabels.USAGE) {
        convertedData.sortBy { it.siteEnergyUseIntensity }
        activeValueLabel = "siteEnergyUseIntensity"
    } else {
        convertedData.sortBy { it.co2Intensity }
        activeValueLabel = "co2Intensity"
    }

    convertedData.reverse()

    var rankings = convertedData
    if (convertedData.size > 5) {
        rankings = convertedData.take(5).toTypedArray()
    }

    Paper {
        className = styles.RANKING_CONTAINER.cssClass
        Box {
            sx {
                display = Display.flex
                justifyContent = JustifyContent.spaceBetween
            }
            Typography {
                variant = TypographyVariant.h6
                sx {
                    marginRight = 30.px
                }
                +"${props.activeRadio.label} Ranking"
            }
            if (convertedData.size > 5) {
                Button {
                    size = Size.medium
                    variant = ButtonVariant.text
                    color = ButtonColor.secondary
                    onClick = {
                        isOpen = true
                    }
                    +"View All"
                }
            }
        }
        Box {
            className = styles.RANKING_CHART.cssClass
            ApiLazyRender {
                dataFetched = props.dataFetched
                ResponsiveContainer {
                    width = "100%"
                    height = "100%"
                    BarChart {
                        layout = "vertical"
                        width = 300
                        height = 500
                        data = JSON.parse(JSON.stringify(rankings))
                        CartesianGrid {
                            stroke = Colors.WHITE_SMOKE
                            horizontal = false
                        }
                        TTip {
                            content = RankingTooltipContent
                            labelStyle = jso {
                                color = Colors.BLACK
                            }
                        }
                        XAxis {
                            type = "number"
                            tick = jso {
                                fill = Colors.WHITE
                                fontSize = 12
                            }
                        }
                        YAxis {
                            dataKey = "facilityName"
                            type = "category"
                            width = 120
                            tick = jso {
                                fill = Colors.WHITE
                                fontSize = 12
                            }
                        }
                        Bar {
                            dataKey = activeValueLabel
                            fill = Colors.AQUAMARINE
                        }
                    }
                }
            }
        }
    }
    Modal {
        open = isOpen
        onClose = { _, _ ->
            isOpen = false
        }
        Box {
            Paper {
                className = styles.RANKING_MODAL_CONTENT.cssClass
                Typography {
                    variant = TypographyVariant.h6
                    sx {
                        marginTop = 20.px
                        textAlign = TextAlign.center
                        fontWeight = FontWeight.bold
                    }
                    +"${props.activeRadio.label} Ranking (${props.title})"
                }
                ApiLazyRender {
                    dataFetched = props.dataFetched
                    Box {
                        sx {
                            width = 100.pct
                            height = 90.pct
                            marginTop = 20.px
                            marginRight = 20.px
                        }
                        Box {
                            sx {
                                width = 100.pct
                                height = 100.pct
                                overflowY = Overflow.scroll
                                paddingRight = 20.px
                            }
                            ResponsiveContainer {
                                width = "100%"
                                height = "100%"
                                BarChart {
                                    layout = "vertical"
                                    width = 300
                                    height = 500
                                    barSize = 5
                                    data = JSON.parse(JSON.stringify(convertedData))
                                    CartesianGrid {
                                        horizontal = false
                                    }
                                    TTip {
                                        content = RankingTooltipContent
                                        labelStyle = jso {
                                            color = Colors.BLACK
                                        }
                                    }
                                    XAxis {
                                        type = "number"
                                        tick = jso {
                                            fill = Colors.WHITE
                                            fontSize = 15
                                        }
                                        tickCount = 9
                                    }
                                    YAxis {
                                        interval = "0"
                                        dataKey = "facilityName"
                                        type = "category"
                                        width = 300
                                        tick = jso {
                                            fill = Colors.WHITE
                                            fontSize = 12
                                        }
                                    }
                                    Bar {
                                        dataKey = activeValueLabel
                                        fill = Colors.AQUAMARINE
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}